import React, { useState } from 'react';
import {
  ChevronRight,
  X,
  Clock,
  Check,
  Instagram,
  Dumbbell,
  Coffee,
  Battery,
  Moon,
  Star,
  Users,
  Calendar,
  BookOpen,
  MessageCircle,
  ChevronDown,
  Heart,
  Shield,
  Zap,
  XCircle,
  AlertCircle,
  Frown,
  Camera,
  FolderSearch,
  Sparkles,
  Waves,
  Flame,
  ArrowRight,
  Home,
  Compass,
  Feather,
} from 'lucide-react';
import background from '../assets/5.jpg';

// Theme Configuration
export const THEME = {
  colors: {
    primary: {
      bg: 'bg-teal-200',
      text: 'text-teal-600',
      border: 'border-teal-600',
      hover: 'hover:bg-teal-50',
    },
    text: {
      dark: 'text-gray-900',
      body: 'text-gray-700',
      light: 'text-gray-600',
      white: 'text-white',
    },
  },
  gradients: {
    section: {
      primary: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
      intense: 'bg-gradient-to-r from-teal-200 via-rose-200 to-teal-200',
      soft: 'bg-gradient-to-r from-teal-50 via-rose-50 to-teal-50',
    },
    card: {
      primary: 'bg-gradient-to-r from-teal-50 to-rose-50',
      white: 'bg-white',
      glass: 'bg-white/90 backdrop-blur-sm',
      highlight: 'bg-gradient-to-r from-teal-100 to-rose-100',
    },
    text: 'bg-clip-text text-transparent bg-gradient-to-r from-teal-600 to-rose-500',
    button: 'bg-gradient-to-r from-teal-600 to-teal-500',
  },
  sections: {
    hero: 'bg-white',
    lookFamiliar: 'bg-white',
    differences: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
    costs: 'bg-white',
    testimonials: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
    recognition: 'bg-white',
    experts: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
    benefits: 'bg-white',
    transformation: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
    faq: 'bg-white',
    truth: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
    cta: 'bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100',
  },
  animations: {
    hover: 'transition-all duration-300 hover:scale-105',
    fade: 'transition-opacity duration-300',
    bounce: 'transition-all duration-300 hover:-translate-y-1',
    slide: 'transition-transform duration-300',
    rotate: 'transition-transform duration-300',
  },
  layout: {
    section: 'py-20',
    container: 'max-w-6xl mx-auto px-4',
    narrowContainer: 'max-w-4xl mx-auto px-4',
  },
  card: {
    base: 'rounded-xl shadow-xl',
    padding: {
      normal: 'p-8',
      compact: 'p-0',
      medium: 'p-6',
    },
  },
  cards: {
    variants: {
      default: 'bg-white',
      white: 'bg-white shadow-xl hover:shadow-2xl',
      feature: 'bg-teal-200 shadow-xl hover:shadow-2xl',
      highlight: 'bg-teal-200 shadow-xl',
      transparent: 'bg-transparent',
      content: 'bg-teal-200 shadow-xl',
      faq: 'bg-teal-200 shadow-xl !p-0',
    },
    states: {
      hover: {
        shadow: 'hover:shadow-md transition-shadow',
        scale: 'transform transition-all duration-300 hover:-translate-y-1',
      },
    },
  },
  content: {
    icons: {
      size: {
        small: 'w-6 h-6',
        medium: 'w-8 h-8',
        large: 'w-12 h-12',
      },
      colors: {
        primary: 'text-teal-600',
        secondary: 'text-rose-400',
        warning: 'text-yellow-400',
      },
    },
    text: {
      heading: {
        primary:
          'text-3xl font-bold text-gray-900 text-center uppercase tracking-wide',
        secondary: 'text-2xl font-semibold text-gray-900',
      },
    },
  },
};

// Base Components
const Card = ({
  children,
  gradient,
  hover,
  className = '',
  variant = 'default',
  padding = 'normal',
}) => {
  return (
    <div
      className={`
      ${THEME.card.base}
      ${THEME.card.padding[padding]}
      ${hover ? THEME.cards.states.hover.scale : ''}
      ${THEME.cards.variants[variant]}
      ${className}
    `}
    >
      {children}
    </div>
  );
};

const Button = ({ children, secondary }) => (
  <button
    className={`
    ${
      secondary
        ? `bg-white ${THEME.colors.primary.text} border-2 ${THEME.colors.primary.border}`
        : THEME.gradients.button
    }
    px-8 py-4 rounded-full font-semibold text-lg
    ${secondary ? THEME.colors.primary.hover : 'hover:opacity-90'}
    ${THEME.animations.hover}
    ${secondary ? THEME.colors.primary.text : THEME.colors.text.white}
  `}
  >
    {children}
  </button>
);

const SectionHeading = ({ title, subtitle }) => (
  <div className="mb-16 text-center">
    <h2
      className={`text-4xl font-bold mb-4 uppercase tracking-wide ${THEME.colors.text.dark}`}
    >
      {title}
    </h2>
    {subtitle && (
      <p className={`text-xl ${THEME.colors.text.light} max-w-2xl mx-auto`}>
        {subtitle}
      </p>
    )}
  </div>
);

// Feature Components
const IconWrapper = ({
  icon: Icon,
  size = 'medium',
  color = 'primary',
  className = '',
}) => (
  <Icon
    className={`
      ${THEME.content.icons.size[size]}
      ${THEME.content.icons.colors[color]}
      ${className}
    `}
  />
);

const IconItem = ({ icon, text, highlight }) => (
  <div
    className={`
    flex items-center space-x-4 bg-white rounded-xl p-4 shadow-sm
    ${highlight ? 'border-2 border-rose-400 bg-rose-50' : ''}
    ${THEME.animations.hover}
  `}
  >
    <IconWrapper icon={icon} color={highlight ? 'secondary' : 'primary'} />
    <span
      className={`text-lg ${
        highlight
          ? `${THEME.colors.text.dark} uppercase tracking-wide font-bold`
          : THEME.colors.text.body
      }`}
    >
      {text}
    </span>
  </div>
);

const TestimonialCard = ({ quote, author, status, highlight }) => (
  <Card hover className="bg-white">
    <div className="bg-teal-50 text-teal-700 py-2 px-4 rounded-full text-center border border-teal-200 mb-8">
      <span className="text-sm font-semibold uppercase tracking-wide">
        {highlight}
      </span>
    </div>
    <blockquote className={`${THEME.colors.text.body} mb-6`}>
      "{quote}"
    </blockquote>
    <div className="flex flex-col items-end">
      <div className="flex space-x-1 mb-2">
        {[...Array(5)].map((_, i) => (
          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
        ))}
      </div>
      <cite
        className={`${THEME.colors.text.dark} font-semibold block not-italic text-right`}
      >
        {author}
      </cite>
      <span className={`${THEME.colors.text.light} text-sm`}>{status}</span>
    </div>
  </Card>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card variant="feature" hover className="text-center">
    <Icon className={`w-12 h-12 ${THEME.colors.primary.text} mb-6 mx-auto`} />
    <h3
      className={`text-xl font-bold ${THEME.colors.text.dark} mb-4 uppercase tracking-wide`}
    >
      {title}
    </h3>
    <p className={THEME.colors.text.body}>{description}</p>
  </Card>
);

const ExpertCard = ({ name, title, description, imageUrl }) => (
  <Card hover className={THEME.gradients.card.white}>
    <img
      src={imageUrl}
      alt={name}
      className="w-32 h-32 rounded-full mx-auto mb-6"
    />
    <h3 className="text-2xl font-semibold text-center text-gray-900 mb-2">
      {name}
    </h3>
    <p className="text-teal-600 text-center mb-4">{title}</p>
    <p className="text-gray-600 text-center">{description}</p>
  </Card>
);

// Main Component
const LandingPageV1 = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const testimonials = [
    {
      quote:
        'I finally deleted my folder of saved protocols and started trusting myself again. The freedom is indescribable.',
      author: 'Sarah M.',
      status: 'Member since 2023',
      highlight: 'No more screenshot hoarding!',
    },
    {
      quote:
        "After years of pushing through fatigue and ignoring my body's signals, I finally learned to listen. My energy has completely transformed.",
      author: 'Jessica R.',
      status: 'Member since 2023',
      highlight: 'Found my natural rhythm',
    },
    {
      quote:
        "The community here is unlike anything I've experienced. No shame, no judgment, just women supporting women in trusting themselves again.",
      author: 'Lauren K.',
      status: 'Member since 2023',
      highlight: 'Real support, real results',
    },
  ];

  const faqs = [
    {
      question: 'How is SHIFT different from other wellness programs?',
      answer:
        "Unlike traditional programs that dictate rules and restrictions, SHIFT helps you reconnect with your body's innate wisdom. We combine functional medicine expertise with deep mindset work to create lasting transformation - no rigid protocols, no shame, just authentic healing.",
    },
    {
      question: "I've tried everything. How do I know this will work?",
      answer:
        "That's exactly why SHIFT is different. We're not adding another protocol to your collection - we're helping you clear away the noise and reconnect with your body's wisdom. Through daily support, expert guidance, and community connection, you'll learn to trust yourself again.",
    },
    {
      question: 'What kind of support will I receive?',
      answer:
        "You'll get daily content, live breakthrough sessions, expert guidance from both Brittany (Functional Medicine) and Ori (Mindset), plus a supportive community of women on similar journeys. All content is recorded and accessible 24/7.",
    },
    {
      question:
        "I'm worried about the time commitment. How much time do I need?",
      answer:
        'SHIFT is designed to fit into your life, not take it over. Our daily content takes just minutes to consume, and you can participate in live sessions or watch the recordings at your convenience. This is about sustainable transformation, not another overwhelming program.',
    },
  ];

  const features = [
    {
      icon: Heart,
      title: 'Daily Support',
      description:
        'Quick implementation videos, expert guidance, and community connection available 24/7',
    },
    {
      icon: Zap,
      title: 'Live Breakthrough Sessions',
      description:
        'Deep-dive sessions with Brittany and Ori to accelerate your transformation',
    },
    {
      icon: Shield,
      title: 'Expert Guidance',
      description:
        'Direct access to functional medicine and mindset expertise when you need it',
    },
  ];

  const results = [
    {
      before: 'Constant anxiety about food choices',
      after: "Peace and confidence in your body's signals",
    },
    {
      before: 'Endless protocol collecting',
      after: 'Clear, intuitive decision making',
    },
    {
      before: "Fighting your body's natural rhythms",
      after: 'Flowing with your unique cycles of energy',
    },
    {
      before: 'Shame around rest and recovery',
      after: "Honoring your body's needs without guilt",
    },
  ];

  const transformations = [
    {
      traditional: {
        title: 'Living in Your Head',
        points: [
          'Constantly second-guessing your choices',
          'Overanalyzing every health decision',
          'Drowning in saved posts and protocols',
          'Feeling paralyzed by conflicting advice',
        ],
      },
      shift: {
        title: 'Coming Home to Your Body',
        points: [
          'Finally trusting your inner knowing',
          'Making decisions from a place of self-love',
          "Feeling deeply connected to your body's wisdom",
          'Moving forward with confidence and clarity',
        ],
      },
    },
    {
      traditional: {
        title: 'Fighting Your Story',
        points: [
          "Believing you're broken and need fixing",
          'Pushing through pain and exhaustion',
          "Ignoring your body's desperate signals",
          'Living in constant shame and guilt',
        ],
      },
      shift: {
        title: 'Embracing Your Journey',
        points: [
          'Recognizing your inherent wholeness',
          "Honoring your body's unique rhythms",
          'Transforming your relationship with yourself',
          'Creating space for deep healing',
        ],
      },
    },
    {
      traditional: {
        title: 'Chasing External Validation',
        points: [
          "Following everyone else's 'perfect' routine",
          'Measuring yourself against impossible standards',
          'Living in fear of making mistakes',
          'Never feeling good enough',
        ],
      },
      shift: {
        title: 'Awakening Your Power',
        points: [
          'Breaking free from limiting beliefs',
          'Creating your own definition of wellness',
          "Trusting your body's deep intelligence",
          'Becoming your own greatest advocate',
        ],
      },
    },
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <header className="relative min-h-screen">
        {/* Background image container */}
        <div className="absolute inset-0">
          <img
            src={background}
            alt="Woman embracing wellness journey"
            className="w-full h-full object-cover"
          />
          {/* Adjusted gradient overlay - less fade, more image visibility */}
          {/* <div className="absolute inset-0" /> */}
          <div className="absolute inset-0 bg-gradient-to-r from-teal-200/90 via-teal-200/50 to-transparent" />
        </div>

        {/* Content */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 pt-32 pb-24">
          <div className="flex flex-col md:flex-row gap-16 items-stretch">
            {/* Left Column - Main Message */}
            <div className="flex-1 flex flex-col justify-center">
              <h1 className="text-6xl font-extrabold mb-8 leading-tight">
                <span className="text-red-600">Stop</span> Listening to Their Rules.
                <br />
                <span className="text-teal-600">Start</span> Trusting Yourself Again.
              </h1>
              <p className="text-2xl text-gray-700 mb-8">
                Tired of Bros telling you to "just eat less" while influencers make you terrified of everything from coffee to carbs?
              </p>
              <div className="flex justify-start">
                <Button>Show Me How</Button>
              </div>
              <p className="mt-4 text-sm text-gray-600 text-left">
                7 Days Free Access, Then $39/month
              </p>
            </div>

            {/* Right Column - Spacer */}
            <div className="flex-1" />
          </div>
        </div>
      </header>

      {/* Look Familiar + Let's Call It Section */}
      <section className={`py-20 ${THEME.sections.lookFamiliar}`}>
        <div className="max-w-7xl mx-auto px-4">
          {/* New Header */}
          <div className="text-center mb-16">
            <h3 className="text-4xl font-bold text-teal-600 uppercase tracking-wide mb-2">
              The Hard Truth?
            </h3>
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 uppercase tracking-wide">
              {/* Your Screenshots Won't Save You */}
              Your Screenshot Collection Is Only Adding To The Problem.
            </h2>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            {/* Look Familiar Side */}
            <Card variant="content" hover className="shadow-xl bg-teal-200">
              <h3 className="text-3xl font-bold mb-12 text-gray-900 text-center uppercase tracking-wide">
                Look Familiar?
              </h3>
              <div className="space-y-6">
                {[
                  {
                    toxic: '147 saved posts',
                    reality: "Of their 'What I eat in a day'",
                    icon: Instagram,
                  },
                  {
                    toxic: '52 screenshots',
                    reality: "Of 'game-changing' protocols",
                    icon: Camera,
                  },
                  {
                    toxic: '23 tabs open',
                    reality: "Of the latest biohack, fads, and diets'",
                    icon: FolderSearch,
                  },
                  {
                    toxic: '0 actual change',
                    reality: 'In how you actually feel',
                    icon: XCircle,
                    highlight: true,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`bg-white rounded-xl p-6 shadow-sm 
                    flex items-center space-x-6 hover:shadow-md transition-shadow
                    ${
                      item.highlight
                        ? 'border-2 border-gray-400 bg-rose-100'
                        : ''
                    }
                  `}
                  >
                    <item.icon
                      className={`w-8 h-8 ${
                        item.highlight ? 'text-rose-400' : 'text-teal-600'
                      } flex-shrink-0`}
                    />
                    <div>
                      <div className="text-lg font-semibold text-gray-900">
                        {item.toxic}
                      </div>
                      <div className="text-gray-600 pl-2">
                        ... {item.reality}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>

            {/* Let's Call It Side */}
            <Card variant="content" hover className="shadow-xl bg-teal-200">
              <h3 className="text-3xl font-bold mb-12 text-gray-900 text-center uppercase tracking-wide">
                Let's Call It What It Is:
              </h3>
              <div className="space-y-6">
                {[
                  {
                    toxic: 'Just count calories!',
                    reality: 'While your body screams for nourishment',
                    icon: Dumbbell,
                  },
                  {
                    toxic: 'No pain, no gain!',
                    reality: 'While every joint hurts',
                    icon: Battery,
                  },
                  {
                    toxic: 'Coffee is toxic!',
                    reality: 'According to that 22-year-old influencer',
                    icon: Coffee,
                  },
                  {
                    toxic: 'Push harder!',
                    reality: 'When your body simply needs rest',
                    icon: Moon,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`bg-white rounded-xl p-6 shadow-sm 
                    flex items-center space-x-6 hover:shadow-md transition-shadow
                  `}
                  >
                    <item.icon className="w-8 h-8 text-rose-400 flex-shrink-0" />
                    <div>
                      <div className="text-lg font-semibold text-gray-900">
                        {item.toxic}
                      </div>
                      <div className="text-gray-600 pl-2">
                        ... {item.reality}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>

          {/* Plot Twist - adjusted spacing */}
          <div className="mt-16">
            <h3 className="text-3xl font-bold text-gray-900 text-center mb-2 uppercase tracking-wide">
              Plot Twist:
            </h3>
            <div className="max-w-4xl mx-auto">
              <Card
                variant="content"
                hover
                className="shadow-2xl bg-rose-300 text-center py-6"
              >
                <div className="text-2xl text-gray-900 font-bold tracking-wide uppercase">
                  You don't need another protocol - <br />
                  <span className="mt-2 inline-block">
                    You need to remember how to trust yourself again!
                  </span>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* New Difference Section */}
      <section className={`py-20 ${THEME.sections.differences}`}>
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className={`${THEME.content.text.heading.primary} mb-6`}>
              From Surviving Their Rules
              <br />
              To Thriving In Your Truth
            </h2>
            <p
              className={`text-xl ${THEME.colors.text.body} max-w-3xl mx-auto`}
            >
              You weren't born doubting your hunger, questioning your rest, or
              apologizing for your needs. Those were learned. And they can be
              unlearned!
            </p>
          </div>

          <Card variant="content" className="!p-8">
            <div className="space-y-8">
              {transformations.map((transform, index) => (
                <div key={index} className="grid md:grid-cols-2 gap-6">
                  {/* Their Way */}
                  <div
                    className={`
                    ${THEME.gradients.card.white} 
                    rounded-xl p-6 relative
                    shadow-md hover:shadow-xl transition-all duration-300
                  `}
                  >
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white px-4 py-1 rounded-full text-sm font-semibold whitespace-nowrap">
                      WHERE YOU'VE BEEN
                    </div>
                    <h3
                      className={`text-xl font-bold ${THEME.colors.text.dark} mt-4 mb-6`}
                    >
                      {transform.traditional.title}
                    </h3>
                    <div className="space-y-4">
                      {transform.traditional.points.map((point, i) => (
                        <div key={i} className="flex items-start space-x-3">
                          <X
                            className={`w-5 h-5 ${THEME.content.icons.colors.secondary} flex-shrink-0 mt-1`}
                          />
                          <span className={THEME.colors.text.body}>
                            {point}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* SHIFT Way */}
                  <div
                    className={`
                    ${THEME.gradients.card.primary} 
                    rounded-xl p-6 relative
                    shadow-md hover:shadow-xl transition-all duration-300
                  `}
                  >
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-teal-600 to-rose-500 text-white px-4 py-1 rounded-full text-sm font-semibold whitespace-nowrap">
                      YOUR TRANSFORMATION
                    </div>
                    <h3
                      className={`text-xl font-bold ${THEME.colors.text.dark} mt-4 mb-6`}
                    >
                      {transform.shift.title}
                    </h3>
                    <div className="space-y-4">
                      {transform.shift.points.map((point, i) => (
                        <div key={i} className="flex items-start space-x-3">
                          <Sparkles
                            className={`w-5 h-5 ${THEME.content.icons.colors.primary} flex-shrink-0 mt-1`}
                          />
                          <span className={THEME.colors.text.body}>
                            {point}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Card>

          <Card hover variant="content" className="mt-8 text-center !p-8">
            <h3 className={`text-2xl font-bold ${THEME.colors.text.dark} mb-6`}>
              This Is Your Invitation to Remember...
            </h3>
            <div
              className={`max-w-3xl mx-auto space-y-4 text-lg ${THEME.colors.text.body}`}
            >
              <p>
                You were born with an unshakeable connection to your body's
                wisdom. You don't need another protocol or program telling you
                what's wrong.
              </p>
              <p>
                You need the space, support, and guidance to break free from
                what's been holding you back and remember who you truly are.
              </p>
            </div>
          </Card>
        </div>
      </section>

      {/* Cost of Staying Stuck Section - New Addition */}
      <section className={`py-20 ${THEME.sections.costs}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading
            title="The Real Cost Isn't Just Another Protocol"
            subtitle="Every day you spend fighting your body's wisdom is a day you could have spent remembering your power."
          />

          <Card variant="content" hover className="mb-12">
            <h3
              className={`text-2xl font-bold ${THEME.colors.text.dark} text-center mb-8`}
            >
              Another Year of Staying Stuck Means...
            </h3>

            <div className="space-y-6">
              {[
                {
                  icon: Clock,
                  title: '3 AM Searches That Lead Nowhere',
                  description:
                    'More nights lost to endless scrolling, saving posts that promise answers but only deliver more confusion. Another year of your precious time spent second-guessing every meal, every rest, every intuition.',
                },
                {
                  icon: Heart,
                  title: 'The Weight of Not Trusting Yourself',
                  description:
                    'Another year of apologizing for your hunger, questioning your fatigue, and feeling broken when their "foolproof" protocols fail you again. The exhaustion of fighting your body instead of listening to its wisdom.',
                },
                {
                  icon: Star,
                  title: "The Joy You're Meant to Be Living",
                  description:
                    "The moments lost to shame spirals. The adventures unlived because you're too tired from fighting yourself. The peace that's waiting on the other side of trusting your body's deep knowing.",
                },
              ].map((item, index) => (
                <div key={index} className="bg-rose-50 rounded-xl p-6">
                  <div className="flex items-start space-x-4">
                    <item.icon
                      className={`w-6 h-6 ${THEME.content.icons.colors.secondary} flex-shrink-0 mt-1`}
                    />
                    <div>
                      <h4
                        className={`text-xl font-semibold ${THEME.colors.text.dark} mb-2`}
                      >
                        {item.title}
                      </h4>
                      <p className={THEME.colors.text.body}>
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="pt-8 border-t-2 border-gray-900 mt-8">
              <h3
                className={`text-2xl font-bold ${THEME.colors.text.dark} text-center mb-6`}
              >
                Or This Could Be Your Turning Point
              </h3>
              <p
                className={`text-center ${THEME.colors.text.body} text-lg mb-8 max-w-2xl mx-auto`}
              >
                Imagine a year from now, looking back on this moment as the day
                you chose to come home to yourself. The day you decided to stop
                fighting and start flowing. The day you remembered who you were
                before they told you that you couldn't be trusted.
              </p>

              <div className="text-center">
                <Button>Choose Your Freedom</Button>
                <p className="mt-4 text-sm text-gray-600">
                  7 Days Free Access, Then $39/month
                </p>
              </div>
            </div>
          </Card>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className={`py-20 ${THEME.sections.testimonials}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title="Women Reclaiming Their Power"
            subtitle="Join hundreds of women who've found freedom through inner wisdom"
          />
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </div>
      </section>

      {/* Recognition Section - Enhanced */}
      <section className={`py-20 ${THEME.sections.recognition}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title={
              <>
                This Isn't Another Wellness Program.
                <br />
                This is Your Revolution.
              </>
            }
          />

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            {/* We See You Card */}
            <Card variant="highlight" hover className="text-center">
              <h3
                className={`text-2xl font-bold ${THEME.colors.text.dark} mb-8`}
              >
                We See You
              </h3>
              <div className="space-y-6">
                <p className={THEME.colors.text.body}>
                  Lying awake at 3 AM, scrolling through endless protocols,
                  feeling broken because their "foolproof" solutions have failed
                  you again.
                </p>
                <p className={THEME.colors.text.body}>
                  Apologizing for your hunger like it's a character flaw,
                  second-guessing every meal, every craving, every signal your
                  body sends.
                </p>
                <p className={THEME.colors.text.body}>
                  Exhausted from trying to biohack your way to worthiness,
                  collecting more protocols than moments of peace with yourself.
                </p>
              </div>
            </Card>

            {/* Here's The Truth Card */}
            <Card variant="highlight" hover className="text-center">
              <h3
                className={`text-2xl font-bold ${THEME.colors.text.dark} mb-8`}
              >
                Here's The Truth
              </h3>
              <div className="space-y-6">
                <p className={THEME.colors.text.body}>
                  Your body isn't a problem to be solved. Your hunger isn't a
                  weakness to be overcome. Your need for rest isn't a failure of
                  discipline.
                </p>
                <p className={THEME.colors.text.body}>
                  You weren't born questioning your body's signals or doubting
                  your own wisdom. You weren't born believing that worthiness
                  could be biohacked.
                </p>
                <p className={`${THEME.colors.text.body} font-semibold`}>
                  You were taught to disconnect from your body's wisdom. And now
                  it's time to remember your way back home.
                </p>
              </div>
            </Card>
          </div>

          {/* Call to Action Card */}
          <Card variant="content" hover className="text-center">
            <h3 className={`text-2xl font-bold ${THEME.colors.text.dark} mb-6`}>
              Your Journey Home Begins Here
            </h3>
            <p
              className={`${THEME.colors.text.body} text-lg max-w-2xl mx-auto mb-8`}
            >
              Freedom isn't found in another protocol or hidden in a wellness
              influencer's promises. Freedom is found in coming home to
              yourself. And we're here to guide you back.
            </p>

            <Button>
              <div className="flex items-center space-x-2">
                <Heart className="w-5 h-5" />
                <span>Begin Your Journey Home</span>
              </div>
            </Button>
            <p className="mt-4 text-sm text-gray-600">
              7 Days Free Access, Then $39/month
            </p>
          </Card>
        </div>
      </section>

      {/* Experts Section */}
      <section className={`py-20 ${THEME.sections.experts}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title="Your Guides on This Journey"
            subtitle="Meet the experts who will support you in reclaiming your power"
          />
          <div className="grid md:grid-cols-2 gap-12">
            <ExpertCard
              name="Brittany Carpenter, MS, RDN/LDN"
              title="Functional Medicine Dietitian & Founder"
              description="Bridging the gap between functional medicine and intuitive healing, Brittany helps you rediscover your body's innate wisdom through evidence-based nutrition."
              imageUrl="/api/placeholder/120/120"
            />
            <ExpertCard
              name="Ori Goldstein"
              title="NLP Trainer & Transformation Specialist"
              description="With 20 years of experience guiding transformational breakthroughs, Ori helps you break free from limiting patterns and reconnect with your inner wisdom."
              imageUrl="/api/placeholder/120/120"
            />
          </div>
        </div>
      </section>

      {/* Membership Benefits Section */}
      <section className={`py-20 ${THEME.sections.benefits}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title="What's Included In Your Membership"
            subtitle="Everything you need to reconnect with your body's wisdom and transform your relationship with wellness"
          />

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: MessageCircle,
                title: 'Live Hot-Seat Coaching',
                description:
                  'Twice monthly breakthrough sessions with both Brittany & Ori for personalized guidance',
                highlight: true,
              },
              {
                icon: Users,
                title: 'Unlimited Expert Support',
                description:
                  'Direct access to Brittany & Ori in our private community for ongoing guidance',
                highlight: true,
              },
              {
                icon: Sparkles,
                title: 'Weekly Expert Tips',
                description:
                  'Regular motivation and practical wisdom to keep you moving forward',
              },
              {
                icon: BookOpen,
                title: 'Foundational System Access',
                description:
                  'Our complete nutrition & mindset framework to rebuild trust with your body',
              },
              {
                icon: Feather,
                title: 'Seasonal Resources',
                description:
                  "Nourishing recipes and nutrition guidance aligned with nature's rhythms",
              },
              {
                icon: Heart,
                title: 'Supportive Community',
                description:
                  'Connect with women who understand your journey and celebrate your growth',
              },
            ].map((benefit, index) => (
              <Card
                key={index}
                variant={benefit.highlight ? 'highlight' : 'feature'}
                hover
                className="text-center"
              >
                <benefit.icon
                  className={`w-12 h-12 ${
                    benefit.highlight
                      ? 'text-rose-500'
                      : THEME.colors.primary.text
                  } mb-6 mx-auto`}
                />
                <h3
                  className={`text-xl font-bold ${THEME.colors.text.dark} mb-4 uppercase tracking-wide`}
                >
                  {benefit.title}
                </h3>
                <p className={THEME.colors.text.body}>{benefit.description}</p>
              </Card>
            ))}
          </div>

          <div className="mt-12 text-center">
            <Button>Join Now</Button>
            <p className="mt-4 text-sm text-gray-600">
              7 Days Free Access, Then $39/month
            </p>
          </div>
        </div>
      </section>

      {/* Transformation Section */}
      <section className={`py-20 ${THEME.sections.transformation}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading
            title={
              <span className="uppercase tracking-wide">
                Imagine What's Possible When...
              </span>
            }
            subtitle="Transform your relationship with your body, mind, and soul"
          />
          <div className="grid gap-8">
            {[
              {
                icon: Home, // Represents feeling at home/comfort
                text: 'You wake up feeling at home in your body instead of at war with it',
              },
              {
                icon: Compass, // Represents inner guidance/trust
                text: 'You make choices from a place of self-trust instead of fear',
              },
              {
                icon: Heart, // Represents emotional connection
                text: 'You feel your feelings without needing to numb them with food or exercise',
              },
              {
                icon: Feather, // Represents lightness/freedom
                text: 'You rest without guilt and move without punishment',
              },
            ].map((item, index) => (
              <Card
                key={index}
                variant="content"
                hover
                className="bg-teal-200 shadow-xl"
              >
                <div className="flex items-center space-x-4">
                  <item.icon className="w-8 h-8 text-teal-700 flex-shrink-0" />
                  <span className="text-xl text-gray-900 font-semibold tracking-wide">
                    {item.text}
                  </span>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className={`py-20 ${THEME.sections.faq}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading
            title="Common Questions"
            subtitle="Everything you need to know about your journey with SHIFT"
          />
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <Card
                key={index}
                variant="content"
                hover
                className="bg-teal-200 shadow-xl !p-0"
              >
                <button
                  className="w-full px-6 py-4 flex justify-between items-center text-left"
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                >
                  <span className="px-4 py-2 font-semibold text-gray-900">
                    {faq.question}
                  </span>
                  <ChevronDown
                    className={`w-5 h-5 text-teal-600 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="bg-teal-50 px-6 pb-4 text-gray-700 rounded-b-xl">
                    {faq.answer}
                  </div>
                )}
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Truth Section */}
      <section className={`py-20 ${THEME.sections.truth}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading title="Here's the Truth They Don't Want You to Know:" />
          <div className="grid gap-6">
            {[
              'You were born knowing exactly what your body needs',
              'Your intuition is more powerful than their certifications',
              "Your body's wisdom outranks their latest study",
              "You're not broken - you've just been taught to ignore your inner knowing",
            ].map((truth, index) => (
              <Card variant="content" hover key={index}>
                <div className="flex items-center space-x-4">
                  <Check className="w-6 h-6 text-teal-600 flex-shrink-0" />
                  <span className="text-lg text-gray-800">{truth}</span>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className={`py-20 ${THEME.sections.features}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title={
              <span className="uppercase tracking-wide">
                Your Support System
              </span>
            }
            subtitle="Expert guidance and community support to help you reconnect with your wisdom"
          />
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className={`py-20 ${THEME.sections.cta}`}>
        <div className="max-w-4xl mx-auto px-4 text-center">
          <SectionHeading
            title="Ready to Remember Your Power?"
            subtitle="Start your risk-free journey today and join our inner circle of women reclaiming their wisdom"
          />
          <Card className="bg-teal-200 shadow-xl">
            <div className="text-2xl font-bold text-gray-900 mb-6 uppercase tracking-wide">
              Your 7-Day Free Trial Includes:
            </div>
            <div className="grid md:grid-cols-3 gap-6 mb-8">
              {[
                {
                  icon: Users,
                  title: 'Daily Support & Community Access',
                  description: '24/7 connection with women on the same journey',
                },
                {
                  icon: Calendar,
                  title: 'Live Breakthrough Sessions',
                  description: 'Weekly transformational group calls',
                },
                {
                  icon: BookOpen,
                  title: 'Expert Guidance & Resources',
                  description: 'Proven tools to reclaim your power',
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`
                  flex flex-col items-center
                  bg-teal-200 p-6 rounded-lg shadow-md
                  transform transition-all duration-300 hover:-translate-y-1
                `}
                >
                  <feature.icon className="w-10 h-10 text-teal-600 mb-4" />
                  <h4 className="font-bold text-gray-900 mb-2">
                    {feature.title}
                  </h4>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <Button>Begin Your Journey</Button>
            <p className="mt-4 text-sm text-gray-700">
              Then just $39/month if you choose to continue
            </p>
            <p className="mt-6 text-sm text-gray-600 italic">
              Not feeling the shift? <br />
              Cancel anytime during your trial - no questions asked.
            </p>
          </Card>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center">
            <div className="text-2xl font-bold mb-4">SHIFT.</div>
            <div className="text-gray-400">Terms | Privacy | Contact</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPageV1;
