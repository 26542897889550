import React from 'react';
import { THEME } from './LandingPageV1'; // You might want to move THEME to a separate config file

const Button = ({ children, secondary }) => (
  <button className={`
    ${secondary ? `bg-white ${THEME.colors.primary.text} border-2 ${THEME.colors.primary.border}` : THEME.gradients.button}
    px-8 py-4 rounded-full font-semibold text-lg
    ${secondary ? THEME.colors.primary.hover : 'hover:opacity-90'}
    ${THEME.animations.hover}
    ${secondary ? THEME.colors.primary.text : THEME.colors.text.white}
  `}>
    {children}
  </button>
);

const Header = ({ version, onVersionChange }) => {
  return (
    <div className="fixed top-0 w-full bg-white/90 backdrop-blur-sm shadow-sm z-50">
      <div className="max-w-6xl mx-auto px-4 py-4 flex items-center relative">
        <div className="text-teal-600 font-bold text-2xl">SHIFT.</div>
        <div className="flex-1 flex justify-end items-center">
          <Button>Start Free Trial</Button>
        </div>
      </div>
      
      {/* <div className="absolute top-4 right-4 flex rounded-lg overflow-hidden border border-teal-600">
        <button 
          onClick={() => onVersionChange('V1')}
          className={`px-4 py-2 text-sm font-semibold transition-colors
            ${version === 'V1' ? 'bg-teal-600 text-white' : 'text-teal-600 hover:bg-teal-50'}`}
        >
          V1
        </button>
        <button 
          onClick={() => onVersionChange('V1Orig')}
          className={`px-4 py-2 text-sm font-semibold transition-colors
            ${version === 'V1Orig' ? 'bg-teal-600 text-white' : 'text-teal-600 hover:bg-teal-50'}`}
        >
          V1_0
        </button>
        <button 
          onClick={() => onVersionChange('V2')}
          className={`px-4 py-2 text-sm font-semibold transition-colors
            ${version === 'V2' ? 'bg-teal-600 text-white' : 'text-teal-600 hover:bg-teal-50'}`}
        >
          V2
        </button>
      </div> */}
    </div>
  );
};

export default Header;
