import React from 'react';
import { X, CheckCircle, ExternalLink, ArrowRight, Instagram, BookOpen, Lock, Unlock } from 'lucide-react';

const LandingPageV2 = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section with bold statement */}
      <header className="relative bg-black text-white py-32">
        <div className="max-w-6xl mx-auto px-4">
          <div className="max-w-3xl">
            <h1 className="text-6xl font-bold mb-8">
              <span className="text-purple-400">Stop</span> Listening to Their Rules.<br/>
              <span className="text-purple-400">Start</span> Trusting Your Power.
            </h1>
            <div className="space-y-4 mb-12">
              <div className="flex items-center space-x-3 text-gray-400">
                <X className="w-5 h-5" />
                <span className="line-through">"Just eat less, move more"</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-400">
                <X className="w-5 h-5" />
                <span className="line-through">"No pain, no gain"</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-400">
                <X className="w-5 h-5" />
                <span className="line-through">"Push through the fatigue"</span>
              </div>
              <div className="flex items-center space-x-3 text-purple-400 mt-6">
                <CheckCircle className="w-5 h-5" />
                <span>Your body has been speaking. We'll help you listen.</span>
              </div>
            </div>
            <button className="bg-purple-600 text-white px-8 py-4 rounded-full font-semibold text-lg hover:bg-purple-700 transition-colors">
              Reclaim Your Wisdom
            </button>
          </div>
        </div>
      </header>

      {/* "The Reality" Section */}
      <section className="py-20 bg-white">
        <div className="max-w-5xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12">
            <div className="bg-red-50 p-8 rounded-3xl">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">Their World:</h3>
              <div className="space-y-4">
                {[
                  "Screenshot collecting becomes your hobby",
                  "Every influencer becomes your guru",
                  "Fear drives your food choices",
                  "Your body becomes the enemy",
                  "Shame becomes your motivation"
                ].map((item, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <Lock className="w-5 h-5 text-red-400" />
                    <span className="text-gray-600">{item}</span>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="bg-purple-50 p-8 rounded-3xl">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">Your Power:</h3>
              <div className="space-y-4">
                {[
                  "Trust your body's signals",
                  "Become your own authority",
                  "Choose from wisdom, not fear",
                  "Work with your body, not against it",
                  "Let intuition guide you"
                ].map((item, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <Unlock className="w-5 h-5 text-purple-600" />
                    <span className="text-gray-600">{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* The Truth Bomb Section */}
      <section className="py-20 bg-black text-white">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-12">The Hard Truth?</h2>
          <div className="text-5xl font-bold mb-12 text-purple-400">
            Your Screenshots Won't Save You
          </div>
          <div className="grid grid-cols-3 gap-6 mb-12">
            <div className="bg-gray-900 p-6 rounded-xl">
              <Instagram className="w-8 h-8 text-purple-400 mb-4 mx-auto" />
              <div className="text-2xl font-bold">147</div>
              <div className="text-gray-400">Saved Posts</div>
            </div>
            <div className="bg-gray-900 p-6 rounded-xl">
              <BookOpen className="w-8 h-8 text-purple-400 mb-4 mx-auto" />
              <div className="text-2xl font-bold">52</div>
              <div className="text-gray-400">Open Tabs</div>
            </div>
            <div className="bg-gray-900 p-6 rounded-xl">
              <ExternalLink className="w-8 h-8 text-purple-400 mb-4 mx-auto" />
              <div className="text-2xl font-bold">0</div>
              <div className="text-gray-400">Real Change</div>
            </div>
          </div>
          <div className="text-xl text-gray-400">
            It's time to stop collecting advice and start connecting with your wisdom.
          </div>
        </div>
      </section>

      {/* Transformation Journey */}
      <section className="py-20 bg-gradient-to-b from-purple-50 to-white">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Your Journey Back to You</h2>
          <div className="space-y-8">
            {[
              {
                phase: "Break Free",
                description: "Leave behind the cycle of shame, comparison, and fear"
              },
              {
                phase: "Reconnect",
                description: "Learn to hear and trust your body's natural wisdom"
              },
              {
                phase: "Transform",
                description: "Become the authority on your own body and health"
              },
              {
                phase: "Thrive",
                description: "Live from a place of power, not fear"
              }
            ].map((stage, index) => (
              <div key={index} className="flex items-center space-x-6">
                <div className="w-16 h-16 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0">
                  <span className="text-2xl font-bold text-purple-600">{index + 1}</span>
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900">{stage.phase}</h3>
                  <p className="text-gray-600">{stage.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-black text-white">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-6">Ready to Trust Yourself Again?</h2>
          <p className="text-xl text-gray-400 mb-12">
            Join our inner circle of women reclaiming their power
          </p>
          <div className="space-y-6">
            <button className="bg-purple-600 text-white px-8 py-4 rounded-full font-semibold text-lg hover:bg-purple-700 transition-colors">
              Begin Your Journey
            </button>
            <div className="flex items-center justify-center space-x-2 text-gray-400">
              <Lock className="w-5 h-5" />
              <span>7 Days Free. Then $39/month.</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPageV2;