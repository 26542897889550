import React, { useState } from 'react';
import { 
  ChevronRight, 
  X, 
  Check, 
  Instagram, 
  Dumbbell, 
  Coffee, 
  Battery, 
  Moon, 
  Star, 
  Users, 
  Calendar, 
  BookOpen, 
  MessageCircle,
  ChevronDown,
  Heart,
  Shield,
  Zap,
  XCircle,
  AlertCircle,
  Frown,
  Camera,
  FolderSearch
} from 'lucide-react';

// Theme Configuration
const THEME = {
  colors: {
    primary: {
      bg: "bg-teal-200",
      text: "text-teal-600",
      border: "border-teal-600",
      hover: "hover:bg-teal-50"
    },
    text: {
      dark: "text-gray-900",
      body: "text-gray-700",
      light: "text-gray-600",
      white: "text-white"
    }
  },
  gradients: {
    section: {
      primary: "bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100",
      intense: "bg-gradient-to-r from-teal-200 via-rose-200 to-teal-200",
      soft: "bg-gradient-to-r from-teal-50 via-rose-50 to-teal-50"
    },
    card: {
      primary: "bg-gradient-to-r from-teal-50 to-rose-50",
      white: "bg-white",
      glass: "bg-white/90 backdrop-blur-sm",
      highlight: "bg-gradient-to-r from-teal-100 to-rose-100"
    },
    text: "bg-clip-text text-transparent bg-gradient-to-r from-teal-600 to-rose-500",
    button: "bg-gradient-to-r from-teal-600 to-teal-500"
  },
  sections: {
    hero: "bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100",
    lookFamiliar: "bg-white",
    truth: "bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100",
    features: "bg-white",
    experts: "bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100",
    transformation: "bg-white",
    testimonials: "bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100",
    faq: "bg-white",
    cta: "bg-gradient-to-r from-teal-100 via-rose-100 to-teal-100"
  },
  animations: {
    hover: "transition-all duration-300 hover:scale-105",
    fade: "transition-opacity duration-300",
    bounce: "transition-all duration-300 hover:-translate-y-1",
    slide: "transition-transform duration-300",
    rotate: "transition-transform duration-300"
  },
  layout: {
    section: "py-20",
    container: "max-w-6xl mx-auto px-4",
    narrowContainer: "max-w-4xl mx-auto px-4"
  },
  card: {
    base: "rounded-xl shadow-xl",
    padding: {
      normal: "p-8",
      compact: "p-0",
      medium: "p-6"
    }
  },
  cards: {
    variants: {
      default: "bg-white",
      feature: "bg-teal-200 shadow-xl hover:shadow-2xl",
      highlight: "bg-teal-200 shadow-xl",
      transparent: "bg-transparent",
      content: "bg-teal-200 shadow-xl",
      faq: "bg-teal-200 shadow-xl !p-0"
    },
    states: {
      hover: {
        shadow: "hover:shadow-md transition-shadow",
        scale: "transform transition-all duration-300 hover:-translate-y-1"
      }
    }
  },
  content: {
    icons: {
      size: {
        small: "w-6 h-6",
        medium: "w-8 h-8",
        large: "w-12 h-12"
      },
      colors: {
        primary: "text-teal-600",
        secondary: "text-rose-400",
        warning: "text-yellow-400"
      }
    },
    text: {
      heading: {
        primary: "text-3xl font-bold text-gray-900 text-center uppercase tracking-wide",
        secondary: "text-2xl font-semibold text-gray-900"
      }
    }
  }
};

// Base Components
const Card = ({ children, gradient, hover, className = "", variant = "default", padding = "normal" }) => {
  return (
    <div className={`
      ${THEME.card.base}
      ${THEME.card.padding[padding]}
      ${hover ? THEME.cards.states.hover.scale : ''}
      ${THEME.cards.variants[variant]}
      ${className}
    `}>
      {children}
    </div>
  );
};

const Button = ({ children, secondary }) => (
  <button className={`
    ${secondary ? `bg-white ${THEME.colors.primary.text} border-2 ${THEME.colors.primary.border}` : THEME.gradients.button}
    px-8 py-4 rounded-full font-semibold text-lg
    ${secondary ? THEME.colors.primary.hover : 'hover:opacity-90'}
    ${THEME.animations.hover}
    ${secondary ? THEME.colors.primary.text : THEME.colors.text.white}
  `}>
    {children}
  </button>
);

const SectionHeading = ({ title, subtitle }) => (
  <div className="mb-16 text-center">
    <h2 className={`text-4xl font-bold mb-4 uppercase tracking-wide ${THEME.colors.text.dark}`}>
      {title}
    </h2>
    {subtitle && (
      <p className={`text-xl ${THEME.colors.text.light} max-w-2xl mx-auto`}>
        {subtitle}
      </p>
    )}
  </div>
);

// Feature Components
const IconWrapper = ({ icon: Icon, size = "medium", color = "primary", className = "" }) => (
  <Icon 
    className={`
      ${THEME.content.icons.size[size]}
      ${THEME.content.icons.colors[color]}
      ${className}
    `}
  />
);

const IconItem = ({ icon, text, highlight }) => (
  <div className={`
    flex items-center space-x-4 bg-white rounded-xl p-4 shadow-sm
    ${highlight ? 'border-2 border-rose-400 bg-rose-50' : ''}
    ${THEME.animations.hover}
  `}>
    <IconWrapper 
      icon={icon}
      color={highlight ? "secondary" : "primary"}
    />
    <span className={`text-lg ${
      highlight 
        ? `${THEME.colors.text.dark} uppercase tracking-wide font-bold` 
        : THEME.colors.text.body
    }`}>
      {text}
    </span>
  </div>
);

const TestimonialCard = ({ quote, author, status, highlight }) => (
  <Card hover className="bg-white">
    <div className="bg-teal-50 text-teal-700 py-2 px-4 rounded-full text-center border border-teal-200 mb-8">
      <span className="text-sm font-semibold uppercase tracking-wide">{highlight}</span>
    </div>
    <blockquote className={`${THEME.colors.text.body} mb-6`}>"{quote}"</blockquote>
    <div className="flex flex-col items-end">
      <div className="flex space-x-1 mb-2">
        {[...Array(5)].map((_, i) => (
          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
        ))}
      </div>
      <cite className={`${THEME.colors.text.dark} font-semibold block not-italic text-right`}>{author}</cite>
      <span className={`${THEME.colors.text.light} text-sm`}>{status}</span>
    </div>
  </Card>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card variant="feature" hover className="text-center">
    <Icon className={`w-12 h-12 ${THEME.colors.primary.text} mb-6 mx-auto`} />
    <h3 className={`text-xl font-bold ${THEME.colors.text.dark} mb-4 uppercase tracking-wide`}>
      {title}</h3>
    <p className={THEME.colors.text.body}>{description}</p>
  </Card>
);

const ExpertCard = ({ name, title, description, imageUrl }) => (
  <Card hover className={THEME.gradients.card.white}>
    <img 
      src={imageUrl} 
      alt={name}
      className="w-32 h-32 rounded-full mx-auto mb-6"
    />
    <h3 className="text-2xl font-semibold text-center text-gray-900 mb-2">
      {name}
    </h3>
    <p className="text-teal-600 text-center mb-4">{title}</p>
    <p className="text-gray-600 text-center">{description}</p>
  </Card>
);

// Main Component
const LandingPageV1Orig = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const testimonials = [
    {
      quote: "I finally deleted my folder of saved protocols and started trusting myself again. The freedom is indescribable.",
      author: "Sarah M.",
      status: "Member since 2023",
      highlight: "No more screenshot hoarding!"
    },
    {
      quote: "After years of pushing through fatigue and ignoring my body's signals, I finally learned to listen. My energy has completely transformed.",
      author: "Jessica R.",
      status: "Member since 2023",
      highlight: "Found my natural rhythm"
    },
    {
      quote: "The community here is unlike anything I've experienced. No shame, no judgment, just women supporting women in trusting themselves again.",
      author: "Lauren K.",
      status: "Member since 2023",
      highlight: "Real support, real results"
    }
  ];

  const faqs = [
    {
      question: "How is SHIFT different from other wellness programs?",
      answer: "Unlike traditional programs that dictate rules and restrictions, SHIFT helps you reconnect with your body's innate wisdom. We combine functional medicine expertise with deep mindset work to create lasting transformation - no rigid protocols, no shame, just authentic healing."
    },
    {
      question: "I've tried everything. How do I know this will work?",
      answer: "That's exactly why SHIFT is different. We're not adding another protocol to your collection - we're helping you clear away the noise and reconnect with your body's wisdom. Through daily support, expert guidance, and community connection, you'll learn to trust yourself again."
    },
    {
      question: "What kind of support will I receive?",
      answer: "You'll get daily content, live breakthrough sessions, expert guidance from both Brittany (Functional Medicine) and Ori (Mindset), plus a supportive community of women on similar journeys. All content is recorded and accessible 24/7."
    },
    {
      question: "I'm worried about the time commitment. How much time do I need?",
      answer: "SHIFT is designed to fit into your life, not take it over. Our daily content takes just minutes to consume, and you can participate in live sessions or watch the recordings at your convenience. This is about sustainable transformation, not another overwhelming program."
    }
  ];

  const features = [
    {
      icon: Heart,
      title: "Daily Support",
      description: "Quick implementation videos, expert guidance, and community connection available 24/7"
    },
    {
      icon: Zap,
      title: "Live Breakthrough Sessions",
      description: "Deep-dive sessions with Brittany and Ori to accelerate your transformation"
    },
    {
      icon: Shield,
      title: "Expert Guidance",
      description: "Direct access to functional medicine and mindset expertise when you need it"
    }
  ];

  const results = [
    {
      before: "Constant anxiety about food choices",
      after: "Peace and confidence in your body's signals"
    },
    {
      before: "Endless protocol collecting",
      after: "Clear, intuitive decision making"
    },
    {
      before: "Fighting your body's natural rhythms",
      after: "Flowing with your unique cycles of energy"
    },
    {
      before: "Shame around rest and recovery",
      after: "Honoring your body's needs without guilt"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <header className={`relative ${THEME.sections.hero} pt-24 pb-24`}>
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h1 className={`text-6xl font-extrabold mb-6 ${THEME.useGradientHeadings ? THEME.gradients.text : 'text-gray-900'}`}>
            Your Body Isn't Broken.<br/>
            <span className="text-teal-600">Their Advice Is!</span>
          </h1>
          <h2 className="text-2xl text-gray-600 max-w-3xl mx-auto mb-12">
            Tired of Bros telling you to "just eat less" while influencers make you terrified of everything from coffee to carbs?
          </h2>
          <div className="space-y-6">
            <Button>Reclaim Your Power!</Button>
            <p className="text-sm text-gray-600">
              7 Days Free Access, Then $39/month
            </p>
          </div>
        </div>
      </header>

      {/* Look Familiar + Let's Call It Section */}
      <section className={`py-20 ${THEME.sections.lookFamiliar}`}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12">
            {/* Look Familiar Side */}
            <Card variant="content" hover className="shadow-xl bg-teal-200">
              <h3 className="text-3xl font-bold mb-12 text-gray-900 text-center uppercase tracking-wide">
                Look Familiar?
              </h3>
              <div className="space-y-6">
                {[
                  {
                    toxic: '147 saved posts',
                    reality: 'While you search for hormone answers',
                    icon: Instagram,
                  },
                  {
                    toxic: '52 screenshots',
                    reality: "Of 'game-changing' protocols",
                    icon: Camera,
                  },
                  {
                    toxic: '23 tabs open',
                    reality: "With the 'latest breakthrough'",
                    icon: FolderSearch,
                  },
                  {
                    toxic: '0 actual change',
                    reality: 'In how you actually feel',
                    icon: XCircle,
                    highlight: true,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`bg-white rounded-xl p-6 shadow-sm 
                    flex items-center space-x-6 hover:shadow-md transition-shadow
                    ${
                      item.highlight
                        ? 'border-2 border-gray-400 bg-rose-100'
                        : ''
                    }
                  `}
                  >
                    <item.icon
                      className={`w-8 h-8 ${
                        item.highlight ? 'text-rose-400' : 'text-teal-600'
                      } flex-shrink-0`}
                    />
                    <div>
                      <div className="text-lg font-semibold text-gray-900">
                        {item.toxic}
                      </div>
                      <div className="text-gray-600 pl-2">
                        ... {item.reality}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>

            {/* Let's Call It Side */}
            <Card variant="content" hover className="shadow-xl bg-teal-200">
              <h3 className="text-3xl font-bold mb-12 text-gray-900 text-center uppercase tracking-wide">
                Let's Call It What It Is:
              </h3>
              <div className="space-y-6">
                {[
                  {
                    toxic: 'Just count calories!',
                    reality: 'While your body screams for nourishment',
                    icon: Dumbbell,
                  },
                  {
                    toxic: 'No pain, no gain!',
                    reality: 'While your adrenals beg for rest',
                    icon: Battery,
                  },
                  {
                    toxic: 'Coffee is toxic!',
                    reality: 'According to that 22-year-old influencer',
                    icon: Coffee,
                  },
                  {
                    toxic: 'Push harder!',
                    reality: "When your body's begging for recovery",
                    icon: Moon,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`bg-white rounded-xl p-6 shadow-sm 
                    flex items-center space-x-6 hover:shadow-md transition-shadow
                  `}
                  >
                    <item.icon className="w-8 h-8 text-rose-400 flex-shrink-0" />
                    <div>
                      <div className="text-lg font-semibold text-gray-900">
                        {item.toxic}
                      </div>
                      <div className="text-gray-600 pl-2">
                        ... {item.reality}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>

          {/* Plot Twist - adjusted spacing */}
          <div className="mt-16">
            <h3 className="text-3xl font-bold text-gray-900 text-center mb-2 uppercase tracking-wide">
              Plot Twist:
            </h3>
            <div className="max-w-4xl mx-auto">
              <Card
                variant="content"
                hover
                className="shadow-2xl bg-rose-300 text-center py-6"
              >
                <div className="text-2xl text-gray-900 font-bold tracking-wide uppercase">
                  You don't need another protocol - <br />
                  <span className="mt-2 inline-block">
                    You need to remember how to trust yourself again!
                  </span>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* Truth Section */}
      <section className={`py-20 ${THEME.sections.truth}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading title="Here's the Truth They Don't Want You to Know:" />
          <div className="grid gap-6">
            {[
              'You were born knowing exactly what your body needs',
              'Your intuition is more powerful than their certifications',
              "Your body's wisdom outranks their latest study",
              "You're not broken - you've just been taught to ignore your inner knowing",
            ].map((truth, index) => (
              <Card variant="content" hover key={index}>
                <div className="flex items-center space-x-4">
                  <Check className="w-6 h-6 text-teal-600 flex-shrink-0" />
                  <span className="text-lg text-gray-800">{truth}</span>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className={`py-20 ${THEME.sections.features}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title={
              <span className="uppercase tracking-wide">
                Your Support System
              </span>
            }
            subtitle="Expert guidance and community support to help you reconnect with your wisdom"
          />
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>

      {/* Experts Section */}
      <section className={`py-20 ${THEME.sections.experts}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title="Your Guides on This Journey"
            subtitle="Meet the experts who will support you in reclaiming your power"
          />
          <div className="grid md:grid-cols-2 gap-12">
            <ExpertCard
              name="Brittany Carpenter, MS, RDN/LDN"
              title="Functional Medicine Dietitian & Founder"
              description="Bridging the gap between functional medicine and intuitive healing, Brittany helps you rediscover your body's innate wisdom through evidence-based nutrition."
              imageUrl="/api/placeholder/120/120"
            />
            <ExpertCard
              name="Ori Goldstein"
              title="NLP Trainer & Transformation Specialist"
              description="With 20 years of experience guiding transformational breakthroughs, Ori helps you break free from limiting patterns and reconnect with your inner wisdom."
              imageUrl="/api/placeholder/120/120"
            />
          </div>
        </div>
      </section>

      {/* Transformation Section */}
      <section className={`py-20 ${THEME.sections.transformation}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading
            title={
              <span className="uppercase tracking-wide">
                Imagine What's Possible When...
              </span>
            }
            subtitle="Transform your relationship with food, body, and self"
          />
          <div className="grid gap-8">
            {[
              {
                icon: Heart,
                text: "You trust your body's signals more than their shame",
              },
              {
                icon: Zap,
                text: 'You understand your unique rhythms instead of following their rules',
              },
              {
                icon: Star,
                text: 'You become your own authority instead of saving another screenshot',
              },
              {
                icon: Users,
                text: 'You join a community of women breaking free from toxic fitness culture',
              },
            ].map((item, index) => (
              <Card variant="content" hover className="bg-teal-200 shadow-xl">
                <div className="flex items-center space-x-4">
                  <item.icon className="w-8 h-8 text-teal-600 flex-shrink-0" />
                  <span className="text-xl text-gray-900 font-semibold tracking-wide">
                    {item.text}
                  </span>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className={`py-20 ${THEME.sections.testimonials}`}>
        <div className="max-w-6xl mx-auto px-4">
          <SectionHeading
            title="Women Reclaiming Their Power"
            subtitle="Join hundreds of women who've found freedom through inner wisdom"
          />
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className={`py-20 ${THEME.sections.faq}`}>
        <div className="max-w-4xl mx-auto px-4">
          <SectionHeading
            title="Common Questions"
            subtitle="Everything you need to know about your journey with SHIFT"
          />
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <Card key={index} variant="content" hover className="bg-teal-200 shadow-xl !p-0">
                <button
                  className="w-full px-6 py-4 flex justify-between items-center text-left"
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                >
                  <span className="px-4 py-2 font-semibold text-gray-900">
                    {faq.question}
                  </span>
                  <ChevronDown
                    className={`w-5 h-5 text-teal-600 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="bg-teal-50 px-6 pb-4 text-gray-700 rounded-b-xl">{faq.answer}</div>
                )}
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className={`py-20 ${THEME.sections.cta}`}>
        <div className="max-w-4xl mx-auto px-4 text-center">
          <SectionHeading
            title="Ready to Remember Your Power?"
            subtitle="Start your risk-free journey today and join our inner circle of women reclaiming their wisdom"
          />
          <Card className="bg-teal-200 shadow-xl">
            <div className="text-2xl font-bold text-gray-900 mb-6 uppercase tracking-wide">
              Your 7-Day Free Trial Includes:
            </div>
            <div className="grid md:grid-cols-3 gap-6 mb-8">
              {[
                {
                  icon: Users,
                  title: 'Daily Support & Community Access',
                  description: '24/7 connection with women on the same journey',
                },
                {
                  icon: Calendar,
                  title: 'Live Breakthrough Sessions',
                  description: 'Weekly transformational group calls',
                },
                {
                  icon: BookOpen,
                  title: 'Expert Guidance & Resources',
                  description: 'Proven tools to reclaim your power',
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`
                  flex flex-col items-center
                  bg-teal-200 p-6 rounded-lg shadow-md
                  transform transition-all duration-300 hover:-translate-y-1
                `}
                >
                  <feature.icon className="w-10 h-10 text-teal-600 mb-4" />
                  <h4 className="font-bold text-gray-900 mb-2">
                    {feature.title}
                  </h4>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            <Button>Begin Your Journey</Button>
            <p className="mt-4 text-sm text-gray-700">
              Then just $39/month if you choose to continue
            </p>
            <p className="mt-6 text-sm text-gray-600 italic">
              Not feeling the shift? <br/>
              Cancel anytime during your trial - no questions asked.
            </p>
          </Card>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center">
            <div className="text-2xl font-bold mb-4">SHIFT.</div>
            <div className="text-gray-400">Terms | Privacy | Contact</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPageV1Orig;