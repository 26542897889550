import { useState } from 'react';
import Header from './components/Header';
import LandingPageV1 from './components/LandingPageV1';
import LandingPageV2 from './components/LandingPageV2';
import LandingPageV1Orig from './components/LandingPageV1Orig';

function App() {
  const [version, setVersion] = useState('V1');

  const renderContent = () => {
    switch (version) {
      case 'V1':
        return <LandingPageV1 />;
      case 'V1Orig':
        return <LandingPageV1Orig />;
      case 'V2':
        return <LandingPageV2 />;
      default:
        return <LandingPageV1 />;
    }
  };

  return (
    <div className="App">
      <Header version={version} onVersionChange={setVersion} />
      {renderContent()}
    </div>
  );
}

export default App;